
import { defineComponent } from 'vue';
import MainLogo from '@/layout/header/partials/MainLogo.vue';
import SignUpForm from '@/components/forms/account/sign-up/SignUpForm.vue';
import { mapGetters, mapActions } from 'vuex';
import { Getters, Actions } from '@/store/enums/UserEnums';

export default defineComponent({
  name: 'referral-sign-up-page',
  components: {
    SignUpForm,
    MainLogo,
  },
  data: () => ({
    referralCode: '',
    isValid: true,
  }),
  mounted() {
    this.handleReferralValidation();
  },
  computed: {
    ...mapGetters({
      user: Getters.GET_USER,
    }),
  },
  methods: {
    ...mapActions({
      fetchUserReferral: Actions.FETCH_USER_REFERRAL,
    }),
    handleReferralValidation() {
      const referralId = this.$route.query.referral;

      return this.fetchUserReferral({ code: referralId })
        .then(async (data) => {
          this.isValid = true;
          this.referralCode = data.data.referral_id as string;
        })
        .catch(() => {
          this.isValid = false;
        });
    },
  },
});
